/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import PropTypes from "prop-types";

import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";

import config from "../styleguide/config";
import shevy from "../styleguide/typography";

import Wrapper from "./wrapper";
import InternalLink from "./internal-link";
import MagicLink from "./magic-link";
import NavTitle from "./nav-title";

const _Header = styled.div`
    /*background: rgba(241, 146, 146, 0.9);*/
    background: rgba(246, 114, 128, 0.8);
    display: block;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const Header = ({ siteTitle, siteTitleLink, siteLinks }) => (
    <_Header>
        <Wrapper
            css={css`
                padding: ${shevy.baseSpacing(1)} ${shevy.baseSpacing(2)};
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 550px) {
                        flex-direction: column;
                        text-align: center;
                    }

                    @media (min-width: 550px) {
                        flex-direction: row;
                    }
                `}
            >
                <NavTitle>
                    <MagicLink as={InternalLink} to={siteTitleLink}>
                        {siteTitle}
                    </MagicLink>
                </NavTitle>

                <ul
                    css={css`
                        color: rgba(255, 255, 255, 0.7);
                        display: flex;
                        font-family: ${config.baseSansSerifFontFamily};
                        font-size: 1rem;

                        list-style: none;

                        margin: 0;
                        padding: 0;

                        @media (max-width: 550px) {
                            justify-content: center;

                            & > li {
                                margin: 0 ${shevy.baseSpacing(0.5)};
                            }
                        }

                        @media (min-width: 550px) {
                            text-align: right;

                            & > li {
                                margin-left: ${shevy.baseSpacing(1)};
                            }
                        }

                        & > li > a {
                            color: inherit;

                            &:hover {
                                color: rgba(255, 255, 255, 0.95);
                            }
                        }
                    `}
                >
                    {siteLinks.map(({ title, link }, i) => (
                        <li key={link + title}>
                            <InternalLink to={link}>{title}</InternalLink>
                        </li>
                    ))}
                </ul>
            </div>
        </Wrapper>
    </_Header>
);

Header.propTypes = {
    siteTitle: PropTypes.string,
    siteTitleLink: PropTypes.string,
    siteLinks: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string,
        })
    ),
};

Header.defaultProps = {
    siteTitle: ``,
    siteTitleLink: `/`,
    siteLinks: [],
};

export default Header;
