import styled from "@emotion/styled";

import Link from "../styleguide/link";

const MagicLink = styled(Link)`
    background: none;
    color: inherit;
    display: inline-block;
    position: relative;
    text-decoration: none;

    &:hover {
        color: rgba(255, 255, 255, 0.6);
    }

    &:hover:after {
        transform: translateY(5px) scale(1.1);
        opacity: 0;
    }

    &:after {
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        background: rgba(255, 255, 255, 0.95);
        bottom: 1px;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
    }
`;

export default MagicLink;
