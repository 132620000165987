import styled from "@emotion/styled";

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 840px;
    min-width: 340px;
    position: relative;
`;

export default Wrapper;
