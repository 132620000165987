import styled from "@emotion/styled";

import config from "../styleguide/config";
import shevy from "../styleguide/typography";

const NavTitle = styled.div`
    color: rgba(255, 255, 255, 0.95);
    font-family: ${config.baseSansSerifFontFamily};
    font-size: 1rem;
    font-weight: 500;
    line-height: ${shevy.lineHeightSpacing(1)};
    margin: 0;
    padding: 0;
`;

export default NavTitle;
