import styled from "@emotion/styled";

import shevy from "./typography";

const Divider = styled.hr`
    background: rgba(0, 0, 0, 0.06);
    border: none;
    margin: ${shevy.baseSpacing(2)} auto;
    height: 5px;
    width: 50%;
`;

export default Divider;
