/** @jsx jsx */
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { useStaticQuery, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";

import { Global, jsx, css } from "@emotion/core";
import { Hash, Rss, GitHub, Twitter, Key } from "react-feather";

import config from "../styleguide/config";
import shevy from "../styleguide/typography";
import GlobalStyles from "../styleguide/global";
import Blockquote from "../styleguide/blockquote";
import Divider from "../styleguide/divider";
import InlineCode from "../styleguide/inline-code";
import ExternalLink from "../styleguide/link";
import Paragraph from "../styleguide/paragraph";
import Preformatted from "../styleguide/preformatted";
import UnorderedList from "../styleguide/unordered-list";
import { H1, H2, H3, H4, H5, H6 } from "../styleguide/heading";

import { MDXWrapper } from "../components/mdx";
import Footer from "../components/footer";
import Header from "../components/header";
import Image from "../components/image";
import MagicLink from "../components/magic-link";

const DefaultLayout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title

                    links {
                        source
                        feed
                        github
                        twitter
                        keybase
                    }
                }
            }
        }
    `);

    const { title, links } = data.site.siteMetadata;

    return (
        <MDXProvider
            components={{
                wrapper: MDXWrapper,
                blockquote: Blockquote,
                h1: H1,
                h2: H2,
                h3: H3,
                h4: H4,
                h5: H5,
                h6: H6,
                inlineCode: InlineCode,
                hr: Divider,
                a: ExternalLink,
                p: Paragraph,
                pre: Preformatted,
                ul: UnorderedList,
                img: Image,
                hash: Hash,
            }}
        >
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&Spectral:ital,wght@0,400;0,700;1,400;1,700&Source+Code+Pro:wght@400;700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <GlobalStyles />
            <Global
                styles={css`
                    ::selection {
                        background: #e8d3ff;
                    }
                    ::-moz-selection {
                        background: #e8d3ff;
                    }

                    body {
                        background: #f4f4f4;
                    }
                `}
            />

            <div
                css={css`
                    display: flex;
                    flex-flow: column;
                    min-height: 100vh;
                `}
            >
                <Header
                    siteTitle={title}
                    siteTitleLink="/"
                    siteLinks={[
                        {
                            title: "About",
                            link: "/about/",
                        },
                        {
                            title: "Contact",
                            link: "/hello/",
                        },
                    ]}
                />

                <main
                    css={css`
                        flex: 1;
                    `}
                >
                    {children}
                </main>

                <Footer>
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;

                            @media (max-width: 550px) {
                                flex-direction: column;
                                text-align: center;
                            }

                            @media (min-width: 550px) {
                                flex-direction: row;
                            }
                        `}
                    >
                        <div>
                            <MagicLink
                                href={links.source}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Handcrafted with{" "}
                                <span
                                    css={css`
                                        color: #f6a7ba;
                                    `}
                                >
                                    &#10084;
                                </span>{" "}
                                by Ian
                            </MagicLink>
                        </div>

                        <ul
                            css={css`
                                color: rgba(255, 255, 255, 0.7);
                                display: flex;
                                font-family: ${config.baseSansSerifFontFamily};
                                font-size: 1rem;

                                list-style: none;

                                margin: 0;
                                padding: 0;

                                @media (max-width: 550px) {
                                    justify-content: center;
                                    margin-top: ${shevy.baseSpacing(0.5)};

                                    & > li {
                                        margin: 0 ${shevy.baseSpacing(0.5)};
                                    }
                                }

                                @media (min-width: 550px) {
                                    text-align: right;

                                    & > li {
                                        margin-left: ${shevy.baseSpacing(1)};
                                    }
                                }

                                & > li > a {
                                    color: inherit;

                                    &:hover {
                                        color: rgba(255, 255, 255, 0.95);
                                    }
                                }
                            `}
                        >
                            <li>
                                <ExternalLink
                                    href={links.feed}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="RSS Feed"
                                >
                                    <Rss />
                                </ExternalLink>
                            </li>
                            <li>
                                <ExternalLink
                                    href={links.github}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="GitHub"
                                >
                                    <GitHub />
                                </ExternalLink>
                            </li>
                            <li>
                                <ExternalLink
                                    href={links.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Twitter"
                                >
                                    <Twitter />
                                </ExternalLink>
                            </li>
                            <li>
                                <ExternalLink
                                    href={links.keybase}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Keybase"
                                >
                                    <Key />
                                </ExternalLink>
                            </li>
                        </ul>
                    </div>
                </Footer>
            </div>
        </MDXProvider>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DefaultLayout;
