/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";

import shevy from "../styleguide/typography";

import Wrapper from "./wrapper";

const _Footer = styled.div`
    /*background: rgba(48, 71, 94, 0.9);*/
    background: rgba(93, 91, 106, 0.9);
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.06);
    color: rgba(255, 255, 255, 0.9);
    display: block;
    position: relative;
    width: 100%;
    z-index: 99;
`;

const Footer = ({ children }) => (
    <_Footer>
        <Wrapper
            css={css`
                padding: ${shevy.baseSpacing(2)} ${shevy.baseSpacing(1)};
            `}
        >
            {children}
        </Wrapper>
    </_Footer>
);

export default Footer;
