import styled from "@emotion/styled";

import config from "./config";
import shevy from "./typography";

const { baseSpacing, content } = shevy;

const Blockquote = styled.blockquote`
  ${content}

  font-family: ${config.baseSerifFontFamily};
  font-style: italic;
  margin-top: ${baseSpacing(1)};
  padding-bottom: ${baseSpacing(0.5)};
  position: relative;

  &:after {
      content: "";
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      position: absolute;
      left: 50%;
      width: 30%;
      transform: translateX(-50%);
  }
`;

export default Blockquote;
