import React from "react";

export const MDXWrapper = ({ onlyExcerpt = false, children }) => {
    let updatedChildren = [...children];

    if (onlyExcerpt) {
        updatedChildren = children.filter((child, _) => {
            return child.props && child.props["data-excerpt"];
        });
    }

    return <>{updatedChildren}</>;
};
