import styled from "@emotion/styled";

import config from "./config";
import shevy from "./typography";

const { baseSpacing, content } = shevy;

const Paragraph = styled.p`
  ${content}

  font-family: ${config.baseSerifFontFamily};
  margin-top: ${baseSpacing(1)};
`;

export default Paragraph;
