import styled from "@emotion/styled";

const Link = styled.a`
    /*color: rgba(85, 111, 181, 0.9);*/
    color: rgba(246, 114, 128, 0.9);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        color: rgba(212, 93, 121, 0.9);
    }

    ${(props) => {
        if (
            !props.href ||
            !props.href.startsWith("http") ||
            (typeof props.children !== "string" &&
                !(props.children instanceof String))
        ) {
            return;
        }

        const url = new URL(props.href);
        const hostname = url.hostname;

        // Alternative: https://favicon.yandex.net/favicon/
        return `
            background: url("https://www.google.com/s2/favicons?domain=${hostname}") left center no-repeat;
            padding-left: 20px;
        `;
    }}
`;

export default Link;
