import React from "react";

import { Global, css } from "@emotion/core";
import { math, normalize } from "polished";

import config from "./config";
import shevy from "./typography";

const { body } = shevy;

const GlobalStyles = () => (
    <Global
        styles={css`
        ${normalize()}

        *, *:before, *:after {
          box-sizing: inherit;
        }

        html {
            box-sizing: border-box;

            margin: 0;
            padding: 0;

            @media (max-width: 550px) {
                font-size: ${math(`${config.baseFontSize} * 0.8`)};
            }

            @media (min-width: 550px) {
                font-size: ${config.baseFontSize};
            }
        }

        body {
            ${body}

            color: ${config.baseFontColour};
            font-family: ${config.baseFontFamily};

            margin: 0;
            padding: 0;
        }
      `}
    />
);

export default GlobalStyles;
