import styled from "@emotion/styled";

import config from "./config";
import shevy from "./typography";

const { baseSpacing, content } = shevy;

const Preformatted = styled.pre`
    ${content}

    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    font-family: ${config.baseMonoFontFamily};
    margin-top: ${baseSpacing(1)};

    & > code {
        font-family: inherit;
    }
`;

export default Preformatted;
