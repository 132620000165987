import styled from "@emotion/styled";

import config from "./config";
import shevy from "./typography";

const InlineCode = styled.code`
    background: rgba(66, 72, 116, 0.9);
    color: rgba(255, 255, 255, 0.95);

    font-size: 0.8rem;
    font-family: ${config.baseMonoFontFamily};

    padding: ${shevy.baseSpacing(0.1)} ${shevy.baseSpacing(0.2)};
`;

export default InlineCode;
