import styled from "@emotion/styled";

import config from "./config";
import shevy from "./typography";

const { baseSpacing, content } = shevy;

const UnorderedList = styled.ul`
  ${content}

  font-family: ${config.baseSerifFontFamily};
  margin-top: ${baseSpacing(1)};
`;

export default UnorderedList;
