import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import defaultThumbnailPath from "../img/thumb.png";

const SEO = ({
    title,
    description,
    url,
    thumbnailPath,
    path = "/",
    type = "website",
    lang = "en",
    meta = [],
}) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl

                        social {
                            twitter
                        }
                    }
                }
            }
        `
    );

    const metaTitle = title || site.siteMetadata.title;
    const metaDescription = description || site.siteMetadata.description;
    const metaUrl = url || new URL(path, site.siteMetadata.siteUrl);
    const metaThumbnailPath = thumbnailPath || defaultThumbnailPath;
    const metaThumbnail = new URL(metaThumbnailPath, site.siteMetadata.siteUrl);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            defaultTitle={site.siteMetadata.title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                // Open Graph
                {
                    property: `og:site_name`,
                    content: site.siteMetadata.title,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: "og:url",
                    content: metaUrl,
                },
                {
                    property: "og:type",
                    content: type,
                },
                {
                    property: "og:image",
                    content: metaThumbnail,
                },
                // Twitter
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.social.twitter,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                ...meta,
            ]}
        />
    );
};

export default SEO;
